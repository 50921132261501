<template>
    <div class="filter-container">
        <div
            class="filter flex-center m-l-16"
            :class="{ selected: filterRegionText.key }"
            @click="onClickFilter('region')"
        >
            <div class="text-wrapper">
                <span v-html="filterRegionText.word ? filterRegionText.word : filterRegionText.title"></span>
            </div>
            <i class="material-icons">keyboard_arrow_down</i>
        </div>
        <div class="filter flex-center m-l-8" :class="{ selected: filterTypeText.key }" @click="onClickFilter('type')">
            <div class="text-wrapper">
                <span v-html="filterTypeText.word ? filterTypeText.word : filterTypeText.title"></span>
            </div>
            <i class="material-icons grey">keyboard_arrow_down</i>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SocialMeetingFilter',
    data: () => ({
        filterRegion: 0,
        filterType: 0,
    }),
    computed: {
        filterRegions() {
            return [
                {
                    title: '전체',
                    word: '지역',
                    key: '',
                    type: 'state',
                },
                {
                    title: '서울',
                    key: '서울',
                    type: 'state',
                },
                {
                    title: '경기',
                    key: '경기',
                    type: 'state',
                },
            ]
        },
        filterRegionText() {
            return this.filterRegions[this.filterRegion]
        },
        filterTypes() {
            return [
                {
                    title: '전체',
                    word: '모임종류',
                    key: '',
                    type: 'type',
                },
                {
                    title: '회원끼리',
                    key: 'group_meeting',
                    type: 'type',
                },
                {
                    title: '친구끼리',
                    key: 'friend_meeting',
                    type: 'type',
                },
            ]
        },
        filterTypeText() {
            return this.filterTypes[this.filterType]
        },
    },
    methods: {
        onClickFilter(key) {
            let filter
            let options = []
            if (key === 'region') {
                filter = this.filterRegion
                options = this.filterRegions
            } else {
                filter = this.filterType
                options = this.filterTypes
            }
            this.$modal
                .custom({
                    component: 'ModalFilter',
                    options: {
                        title: key === 'region' ? 'SOCIAL_REGION' : 'SOCIAL_TYPE',
                        filter: filter,
                        filterOptions: options,
                    },
                })
                .then(idx => {
                    if (idx !== null && idx !== undefined) {
                        if (key === 'region') {
                            this.filterRegion = idx
                            this.$emit('selectFilter', this.filterRegions[this.filterRegion])
                        } else {
                            this.filterType = idx
                            this.$emit('selectFilter', this.filterTypes[this.filterType])
                        }
                    }
                })
        },
    },
}
</script>

<style scoped lang="scss">
.filter-container {
    display: flex;
    flex-direction: row;
    margin-top: 16px;

    color: black;

    .filter {
        height: 36px;
        width: fit-content;
        padding: 8px 12px 8px 16px;
        border-radius: 18px;
        font-size: 14px;
        border: solid 1px $grey-03;
    }

    .clicked {
        background: $grey-03;
    }
    .selected {
        background-color: $blue-facebook;
        border: none;
        color: white !important;

        i {
            color: white;
        }
    }
}
</style>
